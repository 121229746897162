import { ComboBox, ContextualMenu, DefaultButton, Dialog, DialogFooter, DialogType, IComboBox, IComboBoxOption, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Auth from '../Auth';
import DataService from '../data/DataService';
import { IFormDashboard } from '../interfaces/IFormDashboard';
import Navigation from '../layouts/Navigation';

export interface IDashboardProps extends RouteComponentProps {
    description: string;
}

export interface IDashboardState {
    formItems: IFormDashboard[];
    formVisible: boolean;
    selectedApplication: string;
    UserFirstName: string;
    UserLastName: string;
    UserEmail: string;
    UserPhoneNumber: string;
    actionItem: IFormDashboard | undefined;
    deleteDialogHidden: boolean;
    notificationDialogHidden: boolean;
    notificationDialogTitle: string;
    notificationDialogMessage: string;
}

export default class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
    dataService: DataService = new DataService();
    private static FORM_NAMES = [
        { applicationName: 'DirectoryIntakeForm', formName: 'FormDirectoryIntake', displayName: 'Provincial Research and Innovation Directory' },
        { applicationName: 'ServiceRequest', formName: 'FormServiceRequest', displayName: 'AbSPORU Service Request' },
        { applicationName: 'LetterOfSupport', formName: 'FormLetterSupport', displayName: 'AbSPORU Letter of Support Request' },
        // { applicationName: 'ARECCISecondOpinionReview', formName: 'FormARECCISecondOpinion', displayName: 'ARECCI Second Opinion Review Request' },
        // { applicationName: 'CovenantHealthRequest', formName: 'FormCovenantHealthRequest', displayName: 'Covenant Request' },
        { applicationName: 'ACRCCTAConciergeIntakeForm', formName: 'FormACRCCTAConciergeIntake', displayName: 'ACRC/CTA Concierge Intake' },
    ];

    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            formItems: [],
            formVisible: false,
            selectedApplication: '',
            UserFirstName: '',
            UserLastName: '',
            UserEmail: '',
            UserPhoneNumber: '',
            actionItem: undefined,
            deleteDialogHidden: true,
            notificationDialogHidden: true,
            notificationDialogTitle: '',
            notificationDialogMessage: '',
        };
    };

    private async _getFormDasboardItems() {
        let formItems = await this.dataService.getFormItems();
        formItems = formItems.sort((a, b) => {
            if (a.Modified! > b.Modified!) {
                return -1;
            }
            if (a.Modified! < b.Modified!) {
                return 1;
            }
            if (a.formName < b.formName) {
                return -1;
            }
            if (a.formName > b.formName) {
                return 1;
            }
            return 0;
        });
        this.setState({
            formItems: formItems,
            formVisible: true
        });
    }

    private async _getUserInfo() {
        const authResult = await Auth.acquireTokenSilent();
        if (authResult) {
            this.setState({
                UserFirstName: (authResult.idTokenClaims as any).given_name ? (authResult.idTokenClaims as any).given_name : '',
                UserLastName: (authResult.idTokenClaims as any).family_name ? (authResult.idTokenClaims as any).family_name : '',
                UserEmail: (authResult.idTokenClaims as any).emails[0] ? (authResult.idTokenClaims as any).emails[0] : '',
                UserPhoneNumber: (authResult.idTokenClaims as any).extension_PhoneNumber ? (authResult.idTokenClaims as any).extension_PhoneNumber : '',
            });
        }
    }

    private async _deleteFormItem() {
        await this._updateToken();
        const actionItem = this.state.actionItem;
        if (actionItem) {
            const formOperationOutput = await this.dataService.deleteFormItem(actionItem.applicationName, actionItem.applicationId);
            if (formOperationOutput) {
                this.setState({
                    formItems: this.state.formItems.filter(item => item.applicationId !== actionItem.applicationId),
                    deleteDialogHidden: true,
                    actionItem: undefined,
                    notificationDialogHidden: false,
                    notificationDialogTitle: actionItem.applicationName === 'DirectoryIntakeForm' ? 'Delete Form' : 'Delete Application',
                    notificationDialogMessage: actionItem.applicationName === 'DirectoryIntakeForm' ? `Form (${actionItem.applicationId}) deleted.` : `Application (${actionItem.applicationId}) deleted.`,
                });
            } else {
                this.setState({
                    deleteDialogHidden: true,
                    notificationDialogHidden: false,
                    notificationDialogTitle: actionItem.applicationName === 'DirectoryIntakeForm' ? 'Delete Form' : 'Delete Application',
                    notificationDialogMessage: actionItem.applicationName === 'DirectoryIntakeForm' ? 'An error occurred while deleting the form.' : 'An error occurred while deleting the application.',
                });
            }
        }
    }

    private _onComboBoxChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined): void => {
        this.setState({
            selectedApplication: option!.key as string
        });
    }

    private async _startApplication() {
        if (this.state.selectedApplication.length > 0) {
            await this._updateToken();
            this._redirectToPage(this.state.selectedApplication);
        }
    }

    private _redirectToPage(formName: string, applicationId?: string, action?: string) {
        const location = {
            pathname: `/${formName}/${applicationId}`,
            state: { action } // passing the action as part of the location state
        };
        if (applicationId) {
            location.pathname = `/${formName}/${applicationId}`;
        } else {
            location.pathname = `/${formName}`;
        }
        this.props.history.push(location);
    }

    private _displayDeleteDialog(item: IFormDashboard) {
        this.setState({
            deleteDialogHidden: false,
            actionItem: item
        });
    }

    private async _dismissDeleteDialog() {
        return this.setState({
            deleteDialogHidden: true
        });
    }

    private async _dismissNotificationDialog() {
        return this.setState({
            notificationDialogHidden: true,
        });
    }

    private _dismissDirectoryIntakeMessage() {
        localStorage.setItem('dismissDirectoryIntakeMessage', 'true');
        this.setState({
            selectedApplication: ""
        });
    }

    private _loadDirectoryIntakeMessage() {
        let item: JSX.Element | undefined;
        let foundItem: boolean = false;

        if (this.state.formItems.some(item => item.applicationName === 'DirectoryIntakeForm') || localStorage.getItem('dismissDirectoryIntakeMessage') === 'true') {
            foundItem = true;
        }
        if (!foundItem) {
            const link = <span className="link" onClick={async () => {
                await this._updateToken();
                this.setState({
                    selectedApplication: "FormDirectoryIntake"
                });
                this._startApplication();
            }}>Join</span>;
            const action = <span className="action" onClick={() => this._dismissDirectoryIntakeMessage()}>Dismiss</span>;
            item = <section key={`directoryIntakeMessage`} style={{ backgroundColor: 'beige', height: '26px', paddingTop: '10px' }}>
                <div style={{ fontSize: '15px', fontWeight: 600, display: 'grid', gridTemplateColumns: '755px 218px auto', gridTemplateRows: 'auto', alignItems: 'baseline', paddingTop: '9px' }}>
                    <span style={{ gridArea: 'auto / 1' }}>Join our Provincial Research and Innovation Directory</span>
                    <span style={{ paddingLeft: 5, gridArea: 'auto / 2' }}>{link}</span>
                    <span style={{ paddingLeft: 5, gridArea: 'auto / 3' }}>{action}</span>
                </div>
            </section>;
        }
        return item;
    }

    private _loadDashBoardItems() {
        const items: JSX.Element[] = [];
        this.state.formItems.forEach((item: IFormDashboard) => {
            let link = null;
            let action = null;
            let statusColour = '#171717';
            switch (item.status) {
                case 'Draft':
                    link = <span className="link" onClick={async () => { await this._updateToken(); this._redirectToPage(item.formName, item.applicationId, 'draft') }}>Edit draft</span>;
                    action = <span className="action" onClick={() => this._displayDeleteDialog(item)}>Delete</span>;
                    statusColour = '#8A8A8A';
                    break;
                case 'Help Requested':
                    statusColour = '#8A8A8A';
                    break;
                case 'Submitted':
                    link = (
                        <div className="dashboard-link-container">
                            <span className="link" onClick={async () => { await this._updateToken(); this._redirectToPage(item.formName, item.applicationId, 'view') }}>View Application</span>
                            <span className="link" onClick={async () => { await this._updateToken(); this._redirectToPage(item.formName, item.applicationId, 'amend') }}>Submit Amendment</span>
                        </div>
                    );
                    statusColour = '#E29D2F';
                    break;
                case 'Rejected':
                    link = <span className="link" onClick={async () => { await this._updateToken(); this._redirectToPage(item.formName, item.applicationId, 'amend') }}>Submit amendment</span>
                    statusColour = '#C13030';
                    break;
                case 'Screened':
                    link = <span className="link" onClick={async () => { await this._updateToken(); this._redirectToPage(item.formName, item.applicationId, 'view') }}>View Application</span>
                    statusColour = '#479948';
                    break;
                case 'Completed':
                    link = <span className="link" onClick={async () => { await this._updateToken(); this._redirectToPage(item.formName, item.applicationId, 'view') }}>View Application</span>
                    statusColour = '#479948';
                    break;
            }
            items.push(
                <section key={`item_${item.applicationId}`}>
                    <div style={{ fontSize: '15px', fontWeight: 600, display: 'grid', gridTemplateColumns: '315px 240px 200px 232px auto', gridTemplateRows: 'auto', alignItems: 'baseline' }}>
                        <span style={{ gridArea: 'auto / 1' }}>{item.displayName} ({item.applicationId})</span>
                        <span style={{ paddingLeft: 5, gridArea: 'auto / 2' }}>Last modified: {item.Modified?.toLocaleDateString(undefined, { year: "numeric", month: "2-digit", day: "2-digit" })}</span>
                        <span style={{ paddingLeft: 5, gridArea: 'auto / 3' }}>Status: <span style={{ color: statusColour }}>{item.status}</span></span>
                        <span style={{ paddingLeft: 5, gridArea: 'auto / 4' }}>{link}</span>
                        <span style={{ paddingLeft: 5, gridArea: 'auto / 5' }}>{action}</span>
                    </div>
                </section>
            );
        });
        return items;
    }

    private async _updateToken() {
        try {
            await this.dataService.getToken();
        } catch (error) {
            this.props.history.push('/logout');
        }
    }

    public componentDidMount() {
        this._getUserInfo();
        this._getFormDasboardItems();
    }

    public render(): React.ReactElement {
        const { formVisible, UserFirstName, UserLastName, UserEmail, deleteDialogHidden, actionItem, notificationDialogHidden, notificationDialogMessage, notificationDialogTitle } = this.state;
        const spinner = !formVisible ? <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" /> : null;
        const options: IComboBoxOption[] = [
            { key: '', text: '- Select -' },
        ];
        Dashboard.FORM_NAMES.forEach((item: any) => {
            options.push({ key: item.formName, text: item.displayName });
        });
        // if this.state.formItems contains applicationName = 'DirectoryIntakeForm' remove item from options
        if (this.state.formItems.some(item => item.applicationName === 'DirectoryIntakeForm')) {
            options.splice(options.findIndex(item => item.key === 'FormDirectoryIntake'), 1);
        }
        return (
            <main className="App-dashboard">
                <Navigation title="" isForm={false} UserFirstName={UserFirstName} UserLastName={UserLastName} UserEmail={UserEmail} />
                {spinner}
                <form style={!formVisible ? { display: 'none' } : {}}>
                    <div style={{ marginBottom: 20, display: 'grid', gridTemplateColumns: 'auto 320px 160px', alignItems: 'end' }}>
                        <span style={{ gridArea: '1 / 1' }}><h1>Dashboard</h1></span>
                        <span style={{ gridArea: '1 / 2' }}>
                            <ComboBox
                                defaultSelectedKey=""
                                label="Select Application"
                                options={options}
                                styles={{ root: { maxWidth: 300 } }}
                                calloutProps={{ doNotLayer: true }}
                                onChange={this._onComboBoxChange.bind(this)}
                            /></span>
                        <span style={{ gridArea: '1 / 3' }}>
                            <PrimaryButton text="Start Application" onClick={() => this._startApplication()} />
                        </span>
                    </div>
                    {this._loadDirectoryIntakeMessage()}
                    {this._loadDashBoardItems()}
                    <Dialog
                        hidden={deleteDialogHidden}
                        onDismiss={() => { this._dismissDeleteDialog(); }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: actionItem?.applicationName === 'DirectoryIntakeForm' ? 'Delete form' : 'Delete application',
                            subText: actionItem?.applicationName === 'DirectoryIntakeForm' ? `Are you sure you want to delete this form (${actionItem?.applicationId})? This action cannot be undone.` : `Are you sure you want to delete this application (${actionItem?.applicationId})? This action cannot be undone.`,
                        }}
                        modalProps={{
                            isBlocking: true,
                            styles: { main: { maxWidth: 450 } },
                            dragOptions: {
                                moveMenuItemText: 'Move',
                                closeMenuItemText: 'Close',
                                menu: ContextualMenu,
                            },
                        }}
                    >
                        <DialogFooter>
                            <DefaultButton onClick={() => { this._dismissDeleteDialog(); }} text="Cancel" />
                            <PrimaryButton onClick={() => { this._deleteFormItem(); }} text={actionItem?.applicationName === 'DirectoryIntakeForm' ? "Yes, Delete Form" : "Yes, Delete Application"} />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        hidden={notificationDialogHidden}
                        onDismiss={() => { this._dismissNotificationDialog(); }}
                        dialogContentProps={{
                            type: DialogType.close,
                            title: notificationDialogTitle,
                            subText: notificationDialogMessage,
                        }}
                    />
                </form>
            </main>
        );
    }
}